import {
    LightBulbIcon,
    MapIcon,
    CalendarDaysIcon,
} from '@heroicons/react/24/outline'
import type { HeroCardProps } from './HeroCard'

export default [
    {
        title: 'Ressources',
        description:
            'Un répertoire de ressources audiovisuelles en langues régionales, consultables en ligne gratuitement, en quelques clics.',
        icon: <LightBulbIcon className="h-6 w-6" aria-hidden="true" />,
        url: '#browse',
        cta: 'Je consulte',
    },
    {
        title: 'Carte',
        description:
            'Une carte collaborative pour voir les lieux et les événements culturels régionaux, en temps réel !',
        icon: <MapIcon className="h-6 w-6" aria-hidden="true" />,
        url: '/carte',
        cta: "J'explore",
    },
    {
        title: 'Actualités',
        description:
            'Un récapitulatif de tous les évènements à venir en rapport avec les langues régionales, sous forme de frise !',
        icon: <CalendarDaysIcon className="w6 h-6" aria-hidden="true" />,
        url: '/carte/a-venir',
        cta: "Je m'informe",
    },
] satisfies HeroCardProps[]
