import clsx from 'clsx'

export type HeroCardProps = {
    title: string
    description: string
    icon: JSX.Element
    url?: string
    onClick?: () => void
    cta?: string
}

export default function HeroCard({
    title,
    description,
    icon,
    url,
    onClick,
    cta,
}: HeroCardProps) {
    const C = (function () {
        if (url) return 'a'
        else if (onClick) return 'button'
        return 'div'
    })()

    const linkLayout = url !== undefined || onClick !== undefined

    const CTA = (
        <div className="mt-auto w-full">
            <C
                href={url}
                onClick={onClick}
                className="mt-4 inline-flex w-full items-center justify-center space-x-2 rounded bg-primary-300 px-4 py-2.5 font-semibold text-neutral-900 transition-colors hover:bg-primary-400 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
            >
                <span>{cta}</span>
            </C>
        </div>
    )

    return (
        <div
            className={clsx(
                'flex flex-col items-start rounded border border-neutral-200 bg-white p-4',
                linkLayout && 'text-left'
            )}
        >
            <h2 className="mb-2 font-serif text-2xl font-bold text-neutral-800 sm:text-3xl">
                <div className="mr-2 inline-block rounded-full bg-primary-100 p-1">
                    <div className="flex h-9 w-9 items-center justify-center rounded-full bg-primary-200 text-primary-600">
                        {icon}
                    </div>
                </div>
                {title}
            </h2>
            <p className="text-justify text-neutral-700">{description}</p>
            {linkLayout && CTA}
        </div>
    )
}
