import cards from './cards'
import HeroCard from './HeroCard'

export default function Hero({
    title,
    description,
}: {
    title: string
    description: string
}) {
    return (
        <div className="bg-primary-200">
            <div className="custom-container px-4 pt-12 pb-16">
                <h1 className="font-serif text-4xl font-bold text-neutral-900 sm:text-6xl">
                    {title}
                </h1>
                <p className="mt-8 max-w-xl text-base text-neutral-700 sm:text-xl">
                    {description}
                </p>
                <div className="mt-8 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {cards.map((card, i) => (
                        <HeroCard key={i} {...card} />
                    ))}
                </div>
            </div>
        </div>
    )
}
